@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body,
h1,
h2,
h3,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd,
dt {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* {
    box-sizing: border-box;
}

html {
    font-size: 100%;
    width: 100%;
    overflow-x: hidden;
}

body {
    display: block;
    width: 100%;
    font-family: "poppins";
    font-size: 1rem;
    line-height: 1.4;
    color: #000;
    overflow-x: hidden;
    touch-action: manipulation;
    /* background: #000; */
    font-weight: 400;
}

img {
    max-width: 100%;
}
.dropdown-menu1 {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin-top: 5px;
    width: auto;
    border-radius: 12px;
    top: 55px;
  }
  .dropdown-menu1::before {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);

  }
  .cursor{
    cursor: pointer;
  }
  .filterImg{
    margin-left: 5px;
  }
  .dropdown-menu1 ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu1 li {
    padding: 8px 16px;
    cursor: pointer;
  }
  .fist_Sec .data span{
    font-size: 0.789rem;
  }
  .dropdown-menu1 li:hover {
    background-color: #f0f0f0;
  }
  .dropdown-menu1 li:hover:first-child{
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .dropdown-menu1 li:hover:last-child{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .selected {
    font-weight: bold;
    background-color: #f0f0f0;
  }
  
button,
.btn {
    cursor: pointer;
    outline: 0;
    border: 0;
    border-radius: 0;
}
.yes-btn{
    border-radius: 6px;
    background: #4CAF50;
}
.no-btn{
    border-radius: 6px;
    background: #F44236;
}
.offcanvas{
    padding: 40px 40px;
    width: auto;
}
.h2{
    font-size: 1rem !important;
    font-weight: 600 !important;
}
.section-heading.top_heading{
    font-size: 1.5rem;
}
.offcanvas .offcanvas-body{
    overflow-y: hidden;
    overflow-x: hidden;
}
.offcanvas input::placeholder{
    font-size: 10px;
}
.container-1440 {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.container-full {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.login_page {
    width: 100%;
    height: 100vh;
    background: #ffffff;
}

.login_content {
    width: 100%;
    height: 100%;
    display: flex;
}

.wth-350 {
    width: 350px !important;
}

.login_left {
    width: 50%;
    height: 100%;
}

.login_left-content {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #aa0dd1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_left-img-box {
    background: #ffffff2e;
    padding: 15px;
    width: 300px;
    position: relative;
}

.login_left-img-box-content {
    background: #ffffff;
    padding: 20px;
}

.empty_box-b1 {
    width: 60px;
    height: 60px;
    background: #ffffff3e;
    position: absolute;
    top: -20px;
    right: -30px;
    backdrop-filter: blur(10px);
    z-index: 1111;
}

.empty_box-b2 {
    width: 60px;
    height: 60px;
    background: #ffffff3e;
    position: absolute;
    bottom: -25px;
    left: -25px;
    backdrop-filter: blur(10px);
    z-index: 1111;
}

.empty_box-s {
    width: 20px;
    height: 20px;
    background: #ffffff5c;
    position: absolute;
    top: 27px;
    right: -40px;
    backdrop-filter: blur(10px);
    z-index: 1111;
}

.login_right {
    width: 50%;
    height: 100%;
}

.login_right-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login_logo-box {
    width: 150px;
}

.login_right-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0px;
    left: 0;
    padding: 10px;
}

img.help-msg {
    height: 12px;
    width: 16px;
}

.login {
    color: #101828;
    font-size: 1.725rem;
    font-style: normal;
    font-weight: 600;
    margin: 25px 0 15px;
}

.login_desc {
    color: #475467;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 60px;
}

.shadow_card1 {
    width: 220px;
    min-height: 100px;
    position: absolute;
    top: -25px;
    left: -50px;
    border-radius: 12px;
}

.shadow_card2 {
    width: 180px;
    min-height: 100px;
    position: absolute;
    bottom: -95px;
    right: -85px;
    border-radius: 20px;
}

.shadow-cardImg {
    border-radius: 12px;
}

.login_left-text_content {
    margin-top: 50px;
    /* max-width: 80%; */
}

.login_left-title {
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
}

.login_left-text {
    color: #7ab0ff;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.login_left-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
}

.input-box {
    margin-top: 15px;
}
.flex_btn{
    display: flex;
    gap: 10px;
}
.top_form .input-box {
    margin-top: 0;
}
input[type="checkbox"] {
    cursor: pointer;
}

.input-label {
    color: #344054;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
}

.input-label span {
    color: #f00;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
}

.input_field {
    position: relative;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    overflow: hidden;
}

.input {
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 0.625rem 0.875rem;
    overflow: hidden;
    color: #667085;
    font-size: 0.789rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;
}
  .milestone_tb {
    table-layout: fixed;
    width: 100%;
    position: relative; 
   margin-bottom: 10px;
  }
  .helperContainerClass td {
    width: auto !important;        /* Prevent shrinking during drag */
  }
.location_field input{
    height: 38px;
    min-height: 38px;
    border-radius: 8px;
}

.css-13cymwt-control {
    border-radius: 8px !important;
    /* height: 45px;
     min-height: 45px !important;  */
    border: 1px solid #d0d5dd !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}
.css-1cfo1cf{
    font-size: 0.789rem;
}
.css-1nmdiq5-menu{
    font-size: 0.789rem;
}
/* .css-t3ipsp-control {
    min-height: 45px !important;
} */

.register_text {
    margin-top: 15px;
    font-size: 0.875rem;
}
.one_row{
    display: flex;
    align-items: center;
}
.css-1dimb5e-singleValue{
    font-size: 0.8rem;
}
.scroll_y{
    max-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 5px;
}
.edit_icon{
    filter: invert(1) brightness(10000%);
}
.offcanvas-body label{
    max-width: 100%;
}
.offcanvas-body .customtable_box.scroll-v{
    max-height: calc(100vh - 265px);
}
/* 
.scroll_y::-webkit-scrollbar-track {
    background: #ccc;
}

.scroll_y::-webkit-scrollbar-thumb {
    background-color: #e2dcff;
}

.scroll_y::-webkit-scrollbar-thumb:hover {
    background: #dfdceb;
} */

.flex-content{
    display: flex;
    flex-wrap: wrap;
}
.select__menu, .form-check-label{
    font-size: 0.8rem;
}
.select__control{
    height: auto;
}
.coordinate_wrapper{
    position: relative;
}
.second_sec .col-lg-4 button.btn-success{
    font-size: 0.875rem;
    cursor: pointer;
}
.autocomplete-dropdown-container{
    position: absolute;
    top: 50px;
    z-index: 999999999;
    font-size: 0.789rem;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    left: 0;
    background-color: #fff;
    border-radius: 12px;
}
.form-control[readonly]{
    background-color: #fff !important;
}
.input::placeholder, .select__placeholder, .css-1jqq78o-placeholder {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: #667085;
    text-overflow: ellipsis;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    opacity: 1;
}

.contact_submit-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;
    padding: 0 20px;
}

.contact_btn {
    padding: 8px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.2s;
}

.contact_btn:hover {
    transform: scale(1.05);
    transition: all 0.2s;
}

.contact_btn.cancel {
    background: #F4f4f4;
    transition: all 0.2s;
}

.contact_btn.cancel:hover {
    background: #f79f16;
    color: #fff;
    transition: all 0.2s;
}

.contact_btn.cancel {
    background: #F4f4f4;
}

.contact_btn.save {
    background: #079455;
    color: #fff;
}

.submit-btn {
    width: 100%;
    color: #98a2b3;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    background: #f2f4f7;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm, 0.375rem);
    align-self: stretch;
    margin-top: 20px;
    cursor: pointer;
}

.submit-btn.active {
    color: #fff;
    background: #6253AF !important;
}
.second_sec .btn-danger, .third_sec .btn-danger{
    background-color: #fff;
}
.second_sec .btn-info, .third_sec .btn-info{
    background-color: #fff;
}
.second_sec .table_amount .btn-info{
    background-color: #4CAF50;
    margin-right: 10px;
    color: #fff;
}
.second_sec .img_div .btn-info, .btn-success{
    background-color: #4CAF50;
}
.btn-success:hover{
    background-color: #4CAF50;
}
.customtable tr td .btn-success{
    font-size: 0.875rem;
}
.second_sec .table_amount .btn-danger{
    background-color: #F44236;
}
.btn-warning, .btn-warning:hover{
    background-color: #F44236;
    color: #fff;
}

.second_sec .btn-info:focus, .third_sec .btn-info:focus, .third_sec .btn-danger:focus{
    border-color: unset !important;
    box-shadow: none;
}
.submit-btn:hover {
    color: #fff;
    background: #6253AF !important;
}
.offcanvas-header{
    padding: 1rem 0;
}
.inline_field{
    display: flex;
    align-items: center;
    gap: 20px;
}
.inline_field .input_field{
    width: 40%;
}
.inline_field .input-label{
    width: 60%;
}
.sign_up-boxL {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.sign_up-line {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.sign_up-line a {
    color: #aa0dd1;
    text-decoration: none;
}

.login_footer-left {
    color: #475467;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
}

.login_footer-right {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.slider-box {
    /* height: calc(100vh - 100px); */
    width: 450px;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: unset !important;
    bottom: -25px !important;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

/* .slick-prev {}

.slick-next {} */
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #ffffff;
}

.slick-dots li button:before {
    opacity: 0.25;
    color: #fff;
}

.sideMenu {
    width: 17.5vw;
    /* width: 280px; */
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: all 0.3s;
    height: 100vh;
    overflow-y: scroll;
}

.sideMenu::-webkit-scrollbar {
    display: none;
}

.sideMenu_head {
    padding: 0px;
}

.sideMenu-item {
    /* padding: 5px 0; */
    padding: 0.313vw 0;
}

.sideMenu-item {
    color: #aa0dd1 !important;
}

.sidelogo {
    width: 200px;
    display: block;
    margin: 0 auto;
}

.sideLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    /* padding: 10px; */
    padding: 0.625vw;
    border-radius: 8px;
    cursor: pointer;
}

.sideLink_item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.submenu {
    padding: 15px 10px 15px 50px;
}

.submenu_item {
    font-size: 1vw;
    display: flex;
    list-style: none;
    gap: 10px;
    padding: 10px 10px 10px 40px;
}

.sublink {
    color: #344054;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
}

.sideLink_item {
    color: #344054;
    font-size: 1vw;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

.sideLink_item:hover,
.active .sideLink_item {
    color: #aa0dd1 !important;
}

.sideLink_item:hover img,
.navActive .sideLink_item img,
.submenu_item.navActive img {
    color: #aa0dd1 !important;
    transition: all 0.1s;
    filter: brightness(0) saturate(100%) invert(15%) sepia(79%) saturate(6866%) hue-rotate(286deg) brightness(90%) contrast(102%);
}

.submenu_icon {
    width: 25px;
}

.navActive .submenu_icon {
    transition: all 0.1s;
    filter: brightness(0) saturate(100%) invert(26%) sepia(83%) saturate(1717%) hue-rotate(201deg) brightness(96%) contrast(92%);
}

.sideMenu-bottom {
    margin-top: 50px;
}

.side_bottom-card-box {
    border-top: 1px solid #eaecf0;
    padding: 20px 0;
    margin-top: 20px;
    position: relative;
}

.side_bottom-card {
    display: flex;
    align-items: center;
    gap: 10px;
}

.collapse .side_bottom-card {
    display: unset;
}

.bottom_crd-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    border: 1px solid #ebe4e4;
}

/* .collapse .bottom_crd-image {
    margin-left: 10px;
} */

.bottom_crd-image img {
    min-width: 100%;
    min-height: 100%;
}

.bottom_crd-desc {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.bottom_crd-title {
    color: #344054;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

.collapse .bottom_crd-title {
    display: none;
}

.logout_btn {
    cursor: pointer;
    margin-left: auto;
}

.blue {
    color: #aa0dd1 !important;
}

.otp_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.otpInput {
    width: 70px;
    /* padding: 10px; */
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #d0d5dd;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
}

.sideArrow {
    transition: all 0.2s;
}

.submenu_icon,
.sidelink_icon {
    filter: brightness(0) saturate(100%) invert(43%) sepia(5%) saturate(1550%) hue-rotate(183deg) brightness(98%) contrast(85%);
}

.openActive .sideArrow {
    transform: rotate(-90deg);
    transition: all 0.2s;
    filter: brightness(0) saturate(100%) invert(26%) sepia(100%) saturate(554%) hue-rotate(273deg) brightness(92%) contrast(95%);
    /* filter: brightness(0) saturate(100%) invert(26%) sepia(83%) saturate(1717%) hue-rotate(201deg) brightness(96%) contrast(92%); */
}

.navActive .sidelink_icon {
    transition: all 0.1s;
    filter: brightness(0) saturate(100%) invert(26%) sepia(83%) saturate(1717%) hue-rotate(201deg) brightness(96%) contrast(92%);
}

.password_condition {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 8px 0;
}

.message {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    padding: 5px 0;
}

.message.danger {
    color: #d92d20;
}

.message.success {
    color: #079455;
}

.input_field.eror {
    border: 1px solid #fda29b;
}

.collapse_btn {
    position: absolute;
    top: 40%;
    right: -37px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.75rem;
    background: #fcfcfd;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.collapse_sideMenu {
    transform: rotate(90deg);
    transition: all 0.2s;
    filter: brightness(0) saturate(100%) invert(19%) sepia(94%) saturate(4961%) hue-rotate(283deg) brightness(86%) contrast(109%);
    width: 28px;
}

.collapse_sideMenu.active {
    transform: rotate(-90deg);
    transition: all 0.2s;
    filter: brightness(0) saturate(100%) invert(26%) sepia(83%) saturate(1717%) hue-rotate(201deg) brightness(96%) contrast(92%);
}

.sideMenu.collapse {
    transition: all 0.3s;
    width: 3.75rem;
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}

.collapse .sideMenu_head {
    padding: 30px 10px;
    margin-bottom: 30px;
    width: 280px;
    overflow: hidden;
}

/* .collapse .collapse_btn {
    display: none;
} */

.collapse .logout_btn {
    display: none;
}

.collapse .side_bottom-card {
    margin-left: -12px;
}

.collapse .sidelogo {
    margin-left: 7px;
}

button.btn-close {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 111;
}

.modal-dialog {
    /* max-width: 851px !important; */
    margin: 1.75rem auto;
}

.message.danger {
    color: #d92d20;
}

.message.success {
    color: #079455;
}

.input_field.eror {
    border: 1px solid #fda29b;
}

.submenu {
    padding-top: 0.25em;
    height: 0;
    overflow: hidden;
    padding: 0;
    transition: all 0.4s;
}

.sideMenu-item.openActive>.submenu {
    height: auto;
    padding: 15px 0;
}

.danger {
    color: red !important;
}

a {
    text-decoration: none;
    color: #344054;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
}

/*  the material Ui design */

.css-1bnokkt {
    margin: 0px !important;
}

.MuiStepper-root {
    position: absolute;
    top: 3% !important;
    right: 8% !important;
}

.radioButton+.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    display: block !important;
}

/*  global class  */

.lightGrey {
    color: #475467;
}

.MuiTableCell-head {
    font-family: "poppins" !important;
}

.MuiTableCell-body {
    font-family: "poppins" !important;
}

.navActive {
    background: #f9fafb;
}

.custombtn {
    border-radius: 0.5rem !important;
    text-transform: none !important;
}

.custominput {
    border-radius: 0.5rem !important;
}

.custominput .css-hbqe0v-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    border-radius: 0.5rem !important;
}

.custominput legend.css-yjsfm1 {
    border-image: none;
}

.custominput .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-image: none;
    border: 1px solid #d0d5dd !important;
}

.custominput .css-1gjw0us-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #667085 !important;
}

.custominput .DateRangePickerInput__withBorder {
    border-radius: 0.5rem;
    border: 1px solid #d0d5dd;
}

.custominput .DateInput {
    background: transparent;
}

.custominput .DateInput {
    background: transparent;
    width: 115px;
    cursor: pointer;
}

.DateInput__small.DateInput__small_2:first-child .DateInput_input__small {
    border-radius: 0px 0 0 0px !important;
    cursor: pointer;
}

.DateInput__small.DateInput__small_2 .DateInput_input__small {
    border-radius: 0 8px 8px 0 !important;
    cursor: pointer;
}

.DateInput_input__small {
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    padding: 8px 7px 8px !important;
    color: #667085 !important;
}

.sidemenu-container {
    position: relative;
    display: inline-block;
}

.hrp {
    margin: 10px 0;
    padding: 1px;
    background: #eaecf0;
}

.backToDashboard {
    width: 100%;
    text-align: center;
    border: 1px solid white;
    padding: 6px;
    border-radius: 8px;
    color: white;
    background: #aa0dd1;
}

.backToDashboard:hover {
    color: white;
}

/* accepted: "green",
  failure: "red",
  failed: "red",
  in_process: "blue",
  pending: "orange",
  rejected: "purple",
  reversed: "yellow",
  success: "green", */

/*   upload css  */

.imgUpload-head {
    display: flex;
    gap: 10px;
}

.imgHeader_img {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius-lg, 0.625rem);
    border: 1px solid #eaecf0;

    /* Shadows/shadow-xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.imgUpload_ttl {
    color: #101828;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}

.imgUpload_desc {
    color: #475467;

    /* Text sm/Regular */
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.imgUpload_desc a {
    color: #aa0dd1;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-decoration-line: underline;
}

.file_format {
    color: #475467;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.file_format span {
    color: #475467;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
}

.upload_content {
    border-radius: var(--radius-xl, 0.75rem);
    border: 1px solid #eaecf0;
    background: #fff;
    width: 80%;
    margin: 30px auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 5px; */
    cursor: pointer;
}

.upload_btn {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: var(--radius-lg, 0.625rem);
    border: 1px solid #eaecf0;
    cursor: pointer;
}

.upload_btn {
    color: #0318b5;
    /* Text sm/Semibold */
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

/* 142.857% */
/* .upload_btn-txt {
    color: #475467;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
} */
.upload_btn-txt {
    color: #0318b5;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

/* end upload css */

.thHeader {
    padding: 0.875rem 1.5rem !important;
    /* cursor: pointer; */
}

.offcanvas-body {
    padding: 0px !important;
}

::-webkit-scrollbar {
    width: 3px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;

}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.tableBody_ {
    cursor: pointer;
}

.disableRow {
    background-color: #e0e0e0;
    pointer-events: none;
}

.navActive .suLinknk {
    color: #aa0dd1;
}

.openActive .sideLink_item {
    color: #aa0dd1;
}

.navActive .sideLink_item {
    color: #aa0dd1;
}

.backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(7, 14, 29, 0.7);
    z-index: 100;
    display: none;
}

.backdrop.show {
    display: block;
}

/* For tester */

.errorBulkPayout {
    position: relative;
    width: 230px;
}

.errorLogin {
    width: 300px !important;
    /* border: 1px solid grey; */
    padding: 10px;
    border-radius: 8px;
    background-color: white;
}

.contentError {
    font-size: 13px;
    padding: 5px 0px;
}

.blur-row {
    filter: blur(2px);
    cursor: not-allowed;
    display: none;
    /* Adjust the blur intensity as needed */
}

.highlightRow {
    background-color: #f7f8ff !important;
    border-color: 1px solid #727d94 !important;
}

.highlightRow:hover {
    background-color: #f7f8ff !important;
}

.tickIconInput {
    position: absolute;
    right: 3%;
    top: 35%;
}

.eyeIcon {
    top: 25% !important;
    cursor: pointer;
}

.otpInput::placeholder {
    color: #faf6f6;
}

.iconEye {
    color: #d0d5dd;
}

/*  tooltips--------------------csss */
.tooltips {
    width: 15px;
}

/*  end-toltips------------------ */
.slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: #fff !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: 0;
}

img.email_icon {
    margin-right: 7px;
    width: 16px;
    height: 16px;
}

.loader_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0;
    background: #00000080;
}

.hndIcon {
    cursor: pointer !important;
}

.custominput {
    border-radius: 0.5rem;
    border: 1px solid #d0d5dd;
    display: flex;
    padding-left: 10px;
    align-items: center;
}

.custominput .DateRangePickerInput__withBorder {
    border-radius: 0.5rem;
    border: none;
}

img.clndeImgDaterange {
    width: 20px;
    cursor: pointer;
}

.bnfs_added-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.bnfs_added-detail1 {
    color: #475467;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
}

.bnfs_added-detail2 {
    color: #475467;
    text-align: right;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 12px;
}

.bnfs_added-heading {
    color: #101828;
    text-align: center;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
    /* margin: 40px 0; */
}

.section-heading {
    color: #101828;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
    margin-bottom: 10px;
}

.section-sub-heading {
    color: #101828;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}

.imageSuccess img {
    height: 190px !important;
    width: 190px !important;
    /* margin-bottom: 20px; */
}

.beny_right-btn:disabled {
    background-color: #cccccc;
    /* Lighter background color for disabled state */
    color: #666666;
    /* Darker text color for disabled state */
    cursor: not-allowed;
    /* Change cursor to indicate button is not clickable */
}

.modal-dialog {
    outline: none;
}

.MuiButtonBase-root.MuiButton-root {
    border-radius: 0.5rem !important;
    text-transform: capitalize;
    background: transparent;
    text-decoration: underline;
}

.MuiButtonBase-root.MuiButton-root:hover {
    background: none;
}

.upload_text {
    color: #475467;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 20px 0 5px 0;
}

.entries_box {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
}

.entries {
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #475467;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    gap: 5px;
}

.entry_span {
    overflow: hidden;
    color: #079455;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

.upld_current_balance {
    display: flex;
    color: #101828;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.upld_current_balance span {
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #aa0dd1;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

.upld_current_balance img {
    width: 16px;
}

.css-eglki6-MuiLinearProgress-root {
    position: relative;
    overflow: hidden;
    display: block;
    height: 10px !important;
    z-index: 0;
    background: #eaecf0 !important;
    border-radius: 5px !important;
}

.css-5xe99f-MuiLinearProgress-bar1 {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    -webkit-transition: -webkit-transform 0.4s linear;
    transition: transform 0.4s linear;
    transform-origin: left;
    background-color: #aa0dd1;
    border-radius: 5px !important;
}

.css-1hhdbs9-MuiTypography-root {
    margin: 0;
    font-family: poppins;
    font-weight: 500 !important;
    font-size: 0.875rem;
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.6);
}

.upload_del-btn {
    width: 15px;
    filter: brightness(0) saturate(100%) invert(33%) sepia(75%) saturate(1038%) hue-rotate(187deg) brightness(90%) contrast(105%);
    cursor: pointer;
}

.slick-prev:before,
.slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    content: "" !important;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    display: inline-block;
    background: transparent;
    background: url(../assets/images/slick_prev.svg);
}

.slick-prev:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    content: "" !important;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    display: inline-block;
    background: transparent;
    background: url(../assets/images/slick_next.svg);
}

img.close {
    filter: brightness(0) saturate(100%) invert(19%) sepia(54%) saturate(340%) hue-rotate(180deg) brightness(99%) contrast(88%);
}

.css-1fn495t-MuiTypography-root-MuiDialogTitle-root {
    margin: 0;
    font-family: poppins;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    /* padding: 25px 0 0 24px !important; */
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;

}

.css-ypiqx9-MuiDialogContent-root {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    /* padding: 0 !important; */
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    border-radius: 12px !important;
}

/* .page-item {
    pointer-events: none;
    color: #6c757d;
    background-color: transparent !important;
    border: none !important;
    text-transform: capitalize;
    border-radius: 5px;
}

.page-item.previous .page-link {
    pointer-events: none;
    color: #6c757d;
    background-color: transparent !important;
    border-color: #dee2e6;
    text-transform: capitalize;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px #1018280D;
    border: 1px solid #D0D5DD !important;
    float: left;
}

.page-item.disabled .page-link {
    pointer-events: none;
    color: #6c757d;
    background-color: transparent !important;
    border-color: #dee2e6;
    text-transform: capitalize;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px #1018280D;
    border: 1px solid #D0D5DD !important;
    float: left;
} */
/* .page-item.previous .page-link,
.page-item.next .page-link {
    background-color: transparent !important;
    color: #344054 !important;
    border-color: #6c757d;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    border: 1px solid #D0D5DD !important;
    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
} */
.page-item.previous .page-link,
.page-item.next .page-link {
    /* background-color: transparent !important; */
    background-color: #6253AF !important;
    /* color: #344054 !important; */
    color: #fff !important;
    border-color: #6c757d;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    border: 1px solid #d0d5dd !important;
    font-family: Poppins;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.paginated-items {
    width: 100%;
    position: relative;
}

.page-item .page-link {
    background-color: transparent;
    color: #475467 !important;
    background: transparent !important;
    border-radius: 5px !important;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-item.active .page-link {
    background-color: #007bff;
    color: #182230 !important;
    background: #f9fafb !important;
    border-radius: 5px !important;
}

li.page-item.previous {
    /* position: absolute;
    left: 10px;
    width: 100px; */
    margin-right: 20px;
}

li.page-item.next {
    /* position: absolute;
    right: 10px;
    width: 100px; */
    margin-left: 20px;
}

/* 
li.page-item.next .page-link,
li.page-item.previous .page-link {
    width: 110px !important;
} */

li.page-item.disable .page-link {
    background: #cacaca59 !important;
}

li.page-item.disabled {
    cursor: not-allowed !important;
    opacity: 0.4;
}

.page-item.disabled .page-link {
    background-color: #d4d4d487 !important;
    border-color: #dee2e6;
    color: #808283 !important;
    cursor: not-allowed !important;
    user-select: none !important;
}

.crossMark {
    cursor: pointer;
}

img._ExportIcon {
    width: 30px;
}

.expert_payout_ttl {
    margin: 20px 0 10px 0;
}

img.pagination-image {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(106deg) brightness(118%) contrast(112%);
    width: 25px;
}

.page-item.disabled img.pagination-image {
    filter: brightness(0) saturate(100%) invert(40%) sepia(6%) saturate(443%) hue-rotate(165deg) brightness(99%) contrast(89%);
}

.css-uhb5lp {
    border-radius: 12px !important;
}

.css-knqc4i-MuiDialogActions-root {
    padding: 0 !important;
}

.mgb_n10 {
    margin-bottom: -10px;
}

.comingSoon {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.comming_soon-card {
    width: 400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.comming_soon-ttl {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    color: #475467;
}

.comming_soon-desc {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
    color: #475467;
    margin-top: 5px;

}

.comming_soon_arrow {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(300deg) brightness(105%) contrast(102%);
}

.pdl_5 {
    padding-left: 5px !important;
}

.addBuyer_submit-btn {
    display: flex;
    align-items: center;
    padding: 30px;
    justify-content: center;
    gap: 2vw;
}

.data {
    font-weight: 400;
    padding-top: 10px;
}
.measurement_div .input-box{
    margin-top: 0;
}
.services_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.data span {
    font-weight: 600;
    display: block; 
    padding: 5px 0;
    font-size: 14px;
    word-wrap: break-word;
}
.data.description_box span{
    font-size: 0.789rem;
    font-weight: normal;
}
select.input{
    font-size: 0.875rem;
}
.third_sec.payout_data-content .input-label{
    font-size: 1.1rem;
}
.third_sec.payout_data-content .input-box .input-label{
    font-size: 0.82rem;
}

.pointer {
    cursor: pointer;
}
.i_button{
    cursor: pointer;
}
.customtable_box {
    box-shadow: 2px 2px 4px -2px #1018280D, -2px 4px 8px 0px #1018280D;
    border-radius: 8px;
    overflow: scroll;
}

.customtable thead tr {
    background: #DEDBED;
}

.customtable thead tr th {
    font-weight: 600;
    font-size: 0.875rem;
    /* text-align: center; */
}

.customtable tr {
    border-bottom: 1px solid #EAECF0
}

.customtable tr td {
    font-size: 0.75rem;
    font-weight: 500;
    color: #4B4B4B;
    /* text-align: center; */
    border: 0;
    padding: 13px 15px;
}
.customtable tr td a{
    font-size: 0.75rem;
}
.customtable tr th {
    border: 0;
    padding: 13px 15px;
}

.customtable tr:nth-child(even) {
    background-color: #fff;
}

.sidelogoIcon {
    display: none;
    width: 50px;
    margin-left: -20px;
}

.sideMenu.collapse .sidelogoIcon {
    display: block;
}

.sideMenu.collapse .sidelogo {
    display: none;
}

.login_left img {
    object-fit: cover;
    height: 100%;
}

/* sdkssssssssssssssssssssssssssssssssssss */

.card {
    /* background: #d3dafe; */
    border-radius: 10px;
    padding: 1.30vw;
    /* width: 16.875vw; */
    width: 17.5vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* text-align: center; */
}

.one {
    background: #d3dafe !important;
}

.two {
    background: #d1eeea !important;
}

.three {
    background: #ffe8ef !important;
}

.four {
    background: #fff3d4 !important;
}

.card h2 {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
}

.card-content {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
}
h6, .h6{
    font-size: 1.04vw;
}
.card-content .count {
    font-size: 2.1vw;
    color: #333;
    margin-bottom: 10px;
}

.chart-container {
    position: relative;
    width: 100%;
}

.progress-chart {
    width: 80%;
    position: absolute;
    right: 0;
}

.chart-label {
    position: relative;
    left: 18%;
    top: 105%;
    transform: translateY(-50%);
    text-align: center;
}

.chart-label .progress-count {
    font-size: 1rem;
    font-weight: bold;
}

.chart-label .label-text {
    /* display: block; */
    font-size: 0.76vw;
    color: #888;
}

.card-title {
    font-weight: 700;
}

.mixed-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50%;
    height: 100%;
    width: 100%,
}

.graph_lable {
    font-weight: 700;
    position: relative;
    /* left: 12%; */
}

.checkform_box {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
}

.form-check-inline {
    /* display: inline-block; */
    margin-right: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
}

.form-check-inline input {
    margin-bottom: 3px;
}

.tablePage_content_header {
    box-shadow: 2px 2px 4px -2px #1018280D, -2px 4px 8px 0px #1018280D;
    border-radius: 8px;
    padding: 30px 20px !important;
    background: #fff;
    margin: 15px 0 15px 0;
}

.submit_btn_grp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.btn_submit {
    background: transparent;
    font-weight: 500;
    /* font-size: 1rem; */
    font-size: 0.75rem;
    color: #6253AF;
    border-radius: 8px;
    padding: 10px 30px;
    transition: all 0.3s;
    border: 1px solid #6253AF;
}

.btn_submit.active {
    transition: all 0.3s;
    background-color: #6253AF;
    color: #fff;
}

.btn_submit.active:hover {
    transform: scale(1.1);
    transition: all 0.3s;
}

.btn_submit:hover {
    background-color: #6253AF;
    color: #fff;
    transform: scale(1.1);
    transition: all 0.3s;
}

.btn_reject {
    background: #F44236;
    color: #fff;
    border: none;
}

.btn_reject:hover {
    background: #F44236;
    color: #fff;
}

.btn_accept {
    color: #fff;
    background: #4CAF50;
    border: none;

}

.btn_accept:hover {
    background: #4CAF50;
}

.build_search {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 11px;
    cursor: pointer;
}

.build_search img {
    width: 100%;
}

.payout_data_ttl1 {
    font-size: 1.5rem;
    font-weight: 700;
}

.payout_data_addBtn {
    font-size: 1rem;
    font-weight: 700;
    color: #6253AF;
}

th div.filterBox {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 10px;
}

.customtable thead {
    position: relative;
}
.customtable .btn_submit{
    width: 6.25rem;
    padding: 10px 10px;

}
.customtable body {
    border: 1px solid #f4f4f4;
}

.payout_inner_content {
    overflow: auto;
}

/* 
.customtable thead::after {
    content: "";
    height: 20px;
    display: block;
} */

.label-edit-btn{
    margin: 0px 0px 0px 18px !important;
}

.label-tape{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: 9px;
}

.label-icon-btn{
    height: 20px;
    margin: 0px 4px 4px 4px;
}

.col-split{
    border-right: 1px solid black
}
.doc-prev{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}
.doc-prev img{
    width: 80px;
    height: auto;
}
.doc-prev p{
    cursor: pointer;
    text-align: center;
}
.gallary_heading{
    font-weight: 600;
    font-size: 1rem;
}
.doc-prev p:hover{
    text-decoration: underline;
}
.images_list{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}
.image_top_Sec{
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.sideMenu.collapse .submenu_item{
    padding: 10px;
    display: inline-flex;
}
.sideMenu.collapse .sideMenu-item.openActive>.submenu{
    overflow: visible;
}
.add_btn img{
    filter: brightness(0) saturate(100%) invert(61%) sepia(76%) saturate(5767%) hue-rotate(248deg) brightness(96%) contrast(108%);
}
.redirect_span span:hover{
    color: #aa0dd1;
}
.add_btn{
    background: none;
    font-size: 0.875rem;
    cursor: pointer;
    color: #aa0dd1;
    font-weight: 600;
}
input[type="radio"]{
    cursor: pointer;
}
.select__dropdown-indicator{
    cursor: pointer;
}
.images_list .img-prev{
    width: 15.5%;
    cursor: pointer;
}
.custom-imgprev .modal-content{
    background: none;
    border: none;
}
.modal-backdrop.show{
    opacity: 0.7 !important;
}
.custom-imgprev .btn-close{
    opacity: 1;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center / 1em auto no-repeat;
}
.custom-lightbox-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensures that the body fills the modal */
  
  }
  .custom-imgprev .modal-dialog{
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0 auto !important;
    transform: translate(-50%, -50%) !important;
  }

.img-prev{
    background: #f4ecff;
    border-radius: 6px;
    text-align: center;
    padding: 5px;
    margin:4px;
}
.img_div{
    width: 100%;
    height: 155px;
    overflow: hidden;
}
.load_more_div{
    display: flex;
    justify-content: center;
}
.load-more-btn{
    border-radius: 12px;
    padding: 10px 20px;
    background: #e4d9f4;
    font-size: 0.75rem;
}
.img-sub-heading{
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
}
.img_div > img{
    width: 100% !important;
    height: 155px !important;
    object-fit: contain;
}
.ellipsis {
    white-space: nowrap;       /* Prevents text from wrapping to the next line */
    overflow: hidden;          /* Hides the overflowed content */
    text-overflow: ellipsis;   /* Adds ellipses (...) at the point of overflow */
    max-width: 150px;          /* Set a maximum width for the cell */
}
.feedback_ys_no button{

    margin: 0.25rem;
}
.justify-between{
    justify-content: space-between;
}

.badge-warning{
    padding: 0.2rem 0.4rem 0.2rem  0.4rem;
    border-radius: 100px;
    border: 1px solid #dfefab;
    background: #fcfdec;
    position: relative;
    color: #080676;
    font-size: 0.8rem;
    /* font-size: 0.875rem; */
    font-style: normal;
    font-weight: 500;
}
.milestone-btn{
    font-size: 0.875rem;
}
.badge-success{
    padding: 0.2rem 0.4rem 0.2rem  0.4rem;
    border-radius: 100px;
    border: 1px solid #abefc6;
    background: #ecfdf3;
    position: relative;
    color: #067647;
    font-size: 0.8rem;
    /* font-size: 0.875rem; */
    font-style: normal;
    font-weight: 500;
}


.paginationBlock {
    margin-top: 50px;
  }
  
  .paginationBlock .drBottom {
    padding-bottom: 40px;
  }
  
  .drBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 15px;
  }
  
  .centercontent .drBottom {
    padding: 0;
    gap: 0;
  }
  
  .centercontent .drBottom .pagination {
    margin-bottom: 15px;
  }
  
  .pagination {
    margin-bottom: 40px;
    display: flex;
    align-items: end;
  }
  
  .pagination div {
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border-radius: 10px;
    border: solid 2px #000;
    text-align: center;
    margin: 0 3px;
    cursor: pointer;
  }
  
  .pagination div:hover,
  .pagination div.active {
    background: #344054;
    border: solid 2px #fff;
    color: #fff;
  }
  
  .pagination span b {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #000;
    margin-left: 2px;
  }
  
  .pagination div.pagePrev,
  .pagination div.pageNext {
    background: url('../assets//images//paginat_left.svg') no-repeat center center;
    background-size: 100%;
    width: 10px;
    border: none;
  }
  
  .pagination div.pageNext {
    transform: rotate(180deg);
  }
  
  .pageSelect {
    display: flex;
    
    list-style-type: none;
    padding: 0;
    margin: 20px 30px;
    margin-left: auto;

  }
  
  .pageSelectTxt {
    font-size: 15px;
    height: 45px;
    display: flex;
    align-items: center;
  }
  .pageSelect select option{
    cursor: pointer;
  }
  .pageSelect select {
    border: solid 2px #000;
    background: url('../assets//images/arrow-down.svg') no-repeat right 5px center;
    background-size: 15px;
    border-radius: 10px;
    width: 68px;
    height: 45px;
    margin: 0 5px;
    padding-left: 10px;
    box-shadow: none !important;
    cursor: pointer;
  }
  
  p.dots {
    font-weight: 900;
    font-size: 1.6rem;
    margin: 0;
  }
  
  .formHeading-withSearch {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    margin-bottom: 60px;
  }



  .pass_policy{
    background: #ffffd7;
    width: 100%;
    padding: 10px;
  }
  .upload_field .guZdik{
    max-width: 100% !important;
  }
  
/* ImagePopup.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    margin: 0 !important;
}

.popup-content {
    position: relative;
    display: flex;
    justify-content: center;
    width: 80%;
    height: 80%;
    /* max-height: 80%;
     max-width: 50%; */
}

.popup-content img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.close-btn {
    position: absolute;
    top: -40px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
}
.footer_txt_div{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.footer_txt_div .footer_txt{
    font-size: 0.75rem;
    color: #3b3a3a;
}
.footer_txt_div .footer_btn{
    display: flex;
    gap: 10px;
}
