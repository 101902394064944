.payout_page {
    display: flex;
}

.payout_dashboard {
    width: 100%;
    /* padding: 20px 0 0 20px; */
    padding: 1.25vw 0 0 1.25vw;
}
.fitimg{
    width: 20px;
    height: 20px;
}
label.sc-aYaIB{
    width: 100%;
    max-width: 100%;
}
.payout_content {
    /* min-height: 100vh; */
    border-radius: 2.5rem var(--radius-none, 0rem) var(--radius-none, 0rem) var(--radius-none, 0rem);
    border-top: 1px solid #eaecf0;
    border-left: 1px solid #eaecf0;
    background: #fcfcfd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 1.25vw;
    height: calc(100vh - 20px);
    overflow-y: scroll;
}

.payout_content::-webkit-scrollbar {
    display: none;
}

.dashboard_title {
    color: #101828;
    font-size: 1.875vw;
    font-style: normal;
    font-weight: 600;
    line-height: 2.375rem;
}

.dashboard_desc {
    color: #475467;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.dashboard_head-right {
    display: flex;
    align-items: center;
    gap: 5px;
}

.dashboard_need {
    color: #344054;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.dashboard_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 25px 0; */
    padding: 1.563vw 0;
}

.dashboard_cards {
    margin-bottom: 25px;
}

.dashboard_card {
    background: #ffffff;
    border-radius: 10px;
    padding: 30px 20px;
    border-radius: var(--radius-xl, 0.75rem);
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.dashboard_card-left {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.dashboard_card-ttl {
    color: #101828;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
}

.dashboard_card-right {
    width: 20px;
    cursor: pointer;
}

.dashboad_card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.payout_data-content {
    border-radius: var(--radius-xl, 0.75rem);
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 15px;
}

.payout_data-head-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.contractor_ttl, .detail_ttl, .consumer_ttl {
    color: #101828;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    display: flex;
    gap: 20px;
    padding: 0 5px 5px 0;
}

.hits {
    color: #344054;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    border-radius: var(--radius-sm, 0.375rem);
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 2px 5px;
}

.payout_data-desc {
    overflow: hidden;
    color: #475467;
    text-overflow: ellipsis;

    /* Text sm/Regular */
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
}

.add_payout {
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    border-radius: var(--radius-md, 0.5rem);
    background: #175fc9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    /* gap: 10px; */
    align-items: center;
    padding: 10px 20px;
    position: relative;
    cursor: pointer;
}

.payout_down {
    transition: all 0.4s;
    margin-left: 5px;
}

.active .payout_down {
    transform: rotate(-180deg);
    transition: all 0.4s;
}

.addPayout_drop {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 50px;
    right: 0px;
    background: #ffffff;
    padding: 2px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.active .addPayout_drop {
    visibility: visible;
    opacity: 1;
    transition: all 0.2s;
}

.add_payout-drop_item {
    color: #101828;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    padding: 5px 30px 5px 20px;
    border-radius: 8px;
    white-space: nowrap;
}

.add_payout-drop_item.active,
.add_payout-drop_item:hover {
    background: #e3e3e361;
}

.payout_data-head-bottom_left {
    display: flex;
    gap: 30px;
    align-items: center;
}

.payout_data-head-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eaecf0;
    margin: 0 -16px;
    padding: 15px;
}

.searchbox {
    position: relative;
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    overflow: hidden;
    width: 240px;
}

.search_icon {
    width: 20px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

input.seachInput {
    border: none;
    outline: none;
    inset: 0;
    border-radius: 0.5rem;
    display: block;
    overflow: hidden;
    color: #667085;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    padding: 0.5rem 0.875rem 0.5rem 40px;
}

.export_btn {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    color: #868e9a9c;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: not-allowed;
    user-select: none;
}

.export_btn.active {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    color: #344054;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
}

/* .export_btn.active {
    pointer-events: painted;
    pointer-events: painted;
    border: 1px solid #0061c9;
    color: #0061c9;
    cursor: pointer;
}

.export_btn.active img {
    filter: brightness(0) saturate(100%) invert(28%) sepia(88%) saturate(5951%) hue-rotate(202deg) brightness(90%) contrast(101%);
} */
.export_btn img {
    filter: brightness(0) saturate(100%) invert(57%) sepia(6%) saturate(672%) hue-rotate(177deg) brightness(98%) contrast(81%);
}

.export_btn.active img {
    filter: none;
}

.payout_data-head-bottom_right {
    display: flex;
    align-items: center;
    gap: 20px;
}

input.dateRange {
    padding: 8px;
    border: 1px solid #d0d5dd;
    border-radius: var(--radius-md, 0.5rem);
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.payoutHead {
    color: #475467;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: left;
}

.payoutHead_icon {
    width: 15px;
}

.transaction_id.active {
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #364dfc;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px rgba(3, 29, 221, 0.15);
}

.transaction_id {
    border-radius: 0.5rem;
    border: 1px solid #d0d5dd;
    background: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 0.625rem 0.875rem;
    cursor: pointer;
    position: relative;
    color: #101828;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    /* margin-left: 88%; */
}

.transDrop {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 0.25rem 0rem;
    border-radius: 0.375rem;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.active .transDrop {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
    z-index: 999;
}

.transDrop-item {
    color: #101828;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border-radius: 0.375rem;
    background: #ffffff;
    padding: 0.625rem 0.625rem 0.625rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.checkTichImg {
    display: none;
    width: 20px;
    height: 20px;
}

.transDrop-item.active .checkTichImg {
    display: block;
}

.transDrop-item.active,
.transDrop-item:hover {
    background: #f9fafb;
}

.transArrow {
    width: 20px;
    transform: rotate(0deg);
    transition: all 0.3s;
}

.active .transArrow {
    transform: rotate(-180deg);
    transition: all 0.3s;
}

.payout_table-box {
    /* width: 100%; */
    margin: 0 -16px;
}

.tblpaper {
    border-radius: 0;
}

table.payout_table {
    width: 100%;
    padding: 0;
    border-top: solid 1px #ccc;
}

table.payout_table tr {
    border-bottom: 1px solid #eaecf0;
    padding: 5px 0;
}

table.payout_table tr:hover {
    border-top: 2px solid #175ec992;
    border-bottom: 1px solid #175fc9;
    background: #f5f6ff;
}

table.payout_table tr:nth-child(1):hover {
    border-top: none !important;
    border-bottom: 1px solid #eaecf0 !important;
    background: #f9fafb !important;

}

table.payout_table tr:last-child {
    border-bottom: none;
}

table.payout_table thead tr th {
    padding: 0.875rem 1.5rem !important;
    color: #475467 !important;
    font-size: 0.75rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
}

table.payout_table tbody tr td {
    padding: 0.875rem 1.5rem !important;
    color: #475467 !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 400;
}

td.table_amount {
    color: #344054;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600 ;
}

.tableHead {
    background: #f9fafb;
}

.processing::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #f79009;
    position: absolute;
    left: 6px;
    top: 7px;
}

.success {
    width: 140px;
    color: #067647;
    padding: 0.4rem 0.5rem 0.4rem 1.375rem;
    border-radius: 100px;
    border: 1px solid #abefc6;
    background: #ecfdf3;
    position: relative;
    color: #067647;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}

.noData {
    width: 50px;
    color: #475467;
    padding: 0.125rem 0.5rem 0.125rem 1.1rem;
    border-radius: 100px;
    border: 1px solid #47546786;
    background: transparent;
    position: relative;
    color: #475467;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    /* margin: 0 auto; */
}

.noData::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #475467;
    position: absolute;
    left: 6px;
    top: 7px;
}
.status_col div{
    /* margin: 0 auto; */
}
.dollar_sym .input-group-text{
    border: none;
    height: 100%;
    font-size: 0.82rem;
}
.success::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #17b26a;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
}

.processing {
    width: 99px;
    color: #b54708;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    border-radius: 100px;
    border: 1px solid #fedf89;
    background: #fffaeb;
    padding: 0.125rem 0.5rem 0.125rem 1.375rem;
    position: relative;
}
.otp_field{
    display: flex;
    align-items: center;
    gap: 6px;
}
.otp_field button, .otp_input button{
    font-size: 0.789rem;
    border-radius: 8px;
}
.otp_input span{
    font-size: 0.875rem;
    margin-top: 6px;
}
.verified_txt{
    color: green;
    background: #eaeaea;
    padding: 1px 4px;
    border-radius: 5px;
}
.suggestions-list{
    border-radius: 8px;
}
.suggestion-item {
    font-size: 0.789rem;
}
.reg_mandate {
    color: #067647;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    border-radius: 100px;
    border: 1px solid #067647;
    background: #ecfdf3;
    position: relative;
    padding: 2px 10px;
}

.modal-header {
    border: none;
}

.swich-box {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
    /* border: 1px solid #EAECF0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); */
}

.create_payout-head_left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.payout_head-ttl {
    color: #101828;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
}

.payout_head-desc {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    /* margin-top: 8px; */
}

.payment_mode {
    border-bottom: 2px solid #eaecf0;
    padding-bottom: 20px;
}

.payment_title {
    color: #101828;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    margin: 20px 0 10px 0;
}

.payment_title span {
    color: #ff6c61;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.payment_cards {
    display: flex;
    gap: 10px;
    align-items: center;
}

.payment_card {
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 0.675rem;
    flex: 1 0 0;
    border-radius: 0.75rem;
    border: 1px solid #eaecf0;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.payment_card.active {
    border-radius: var(--radius-xl, 0.75rem);
    border: 2px solid #175fc9;
    background: #f5f6ff;
}

.payment_cardImg {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #ebedff;
}

.payment_card_text {
    color: #344054;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
}

.banificiary_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 10px 0;
}

.banificiary_bottom {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.bani_ttl {
    color: #101828;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
}

.add_beni_btn {
    display: flex;
    padding: 0.625rem 0.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid #175fc9;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #175fc9;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
}

.bene_table-check {
    height: 20px;
    width: 20px;
    /* clip-path: circle(46% at 50% 50%); */
    /* border-radius: 20%; */
}

/* .baneficiary_table-box {
    max-height: 300px;
    overflow-y: scroll;
} */
.baneficiary_table-box {
    max-height: 300px;
    overflow-y: scroll;
    border-radius: 15px;
    border: 1px solid #EAECF0;
}

.baneficiary_table-box::-webkit-scrollbar {
    /* display: none; */
    /* height: 100px; */
}

.beneficial_table .tableHead {
    position: sticky;
    top: -1px;
    z-index: 1;
    background: #f9fafb;
}

.beneficial_table {
    width: 100%;
    border: 1px solid #eaecf0;
    border-radius: 30px;
    border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    -webkit-border-radius: 6px 6px 6px 6px;
    overflow: scroll;
}

.beneficial_table tr {
    border-bottom: 1px solid #eaecf0;
    /* background: #F9FAFB; */
    padding: 3px 0;
}

.beneficial_table tr:last-child {
    border: none;
}

.beneficial_table tr:hover {
    background: #f7f8ff;
}

.beneficial_table tr:nth-child(1) {
    background: #f9fafb;
}

.beneficial_table th,
.beneficial_table td {
    color: #475467;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    padding: 15px 10px;
}

.beneficial_table td {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    padding: 15px 10px;
}

td.payout_name {
    color: #101828;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.acc_no {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
}

.action {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 15px;
}

.edit_action {
    cursor: pointer;
}

img.action_img {
    width: 20px;
    height: 20px;
}

.create_payout-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: url(../../src/assets/images/bgpattern.png) no-repeat;
    background-position: 0 0; */

}

.create_payout-progresBar {
    display: flex;
    align-items: center;
    gap: 30px;
    object-fit: contain;
}

.progress_card {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.progress_fill {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
    background: #fff;
    position: relative;
}

.prf2::before {
    content: "";
    width: 80px;
    height: 3px;
    background: #eaecf0;
    position: absolute;
    top: 19px;
    left: -90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active .prf2::before {
    background: #175fc9;
}

/* .prf1::before {
    content: "";
    width: 80px;
    height: 3px;
    background: #eaecf0;
    position: absolute;
    top: 19px;
    left: -90px;
} */

.prf1,
.prf2 {
    padding: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prf2 {
    padding: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prf2 img,
.prf1 img {
    width: 30px;
    height: 30px;
}

/* .active .prf1::before {
    background: #175fc9;
} */

.progress_blue_box {
    width: 30px;
    height: 30px;
    background: #eaecf0;
    border-radius: 50%;
    padding: 10px;
}

.progress_white_box {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #d0d5dd;
}

.progress_sec {
    color: #475467;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
}

.progress_white_box {
    background: #ffffff;
}

/* .active .progress_fill {
    background: #175ec957;
} */

.active .progress_blue_box {
    background: #175fc9;
}

.active .progress_white_box {
    background: #ffffff;
}

.active .progress_sec {
    color: #175fc9;
    font-weight: 600;
}

.benyf_btn {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.newClass {
    pointer-events: none;
}

.beny_right-btn {
    display: flex;
    width: 100%;
    padding: 0.625rem var(--spacing-xl, 1rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm, 0.375rem);
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #eaecf0;
    background: #f2f4f7;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
    color: #98a2b3;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
}

.beny_right-btn.active {
    border: 1px solid #175fc9;
    background: #175fc9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    pointer-events: painted;
}

.active .bany_next {
    filter: brightness(0) saturate(100%) invert(94%) sepia(94%) saturate(23%) hue-rotate(1deg) brightness(107%) contrast(105%);
}

.beny_left-btn {
    color: #344054;
    width: 100%;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    display: flex;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #d0d5dd;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.payment_methoed {
    display: flex;
    flex-wrap: wrap;
    border-radius: 0.75rem;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px;
    margin-top: 20px;
}

.pay_methoed-card-box {
    width: 50%;
}

.pay_methoed-card {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 0;
}

.payment_detail {
    margin-top: 10px;
    padding: 30px 0 0 0;
    position: relative;
}

.payment_detail::before {
    content: "";
    height: 1px;
    width: 100%;
    background: #eaecf0;
    position: absolute;
    top: 10px;
    left: 0;
}

.pay_methoed-card-img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.pay_methoed-card-text {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
}

.pay_methoed-card-ttl {
    color: #101828;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
}

.payment_detail-ttl {
    color: #101828;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
}

.current_balance {
    color: #101828;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    padding: 10px 0;
}

.current_balance span {
    color: #175fc9;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.imp_note {
    padding: 20px;
    display: flex;
    padding: var(--spacing-2xl, 1.25rem) var(--spacing-xl, 1rem);
    flex-direction: column;
    background: #f9fafb;
    position: relative;
    margin-bottom: 25px;
    border-radius: 0.5rem;
}

.note_ttl {
    color: #101828;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.note_text {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    padding: 5px 0;
}

.note_text span {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.note_check {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    padding: 5px 0 10px 0;
    cursor: pointer;
}

#note_checkbox {
    height: 16px;
    width: 16px;
}

.note_close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.btn_dismiss {
    color: #175fc9;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    background: none;
    border: none;
    outline: none;
}

.comfirm_pay_methoed {
    border-radius: 0.75rem;
    border: 1px solid #eaecf0;
    background: #f9fafb;
}

.payment_otp {
    justify-content: left;
    margin-bottom: 20px;
}

.send_succ {
    color: #475467;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
}

.send_succ span {
    color: #101828;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
}

.notRecieve a {
    color: #175fc9;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    text-decoration-line: underline;
}

.notRecieve {
    color: #101828;
    text-align: right;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.send_otp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
}

.payout_success-img {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dcfae6;
    border-radius: 50%;
}

.payout_success-ttl {
    color: #101828;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    padding: 10px 0;
}

.payout_cont-text {
    padding: 5px;
}

.payout_success_pop {
    padding: 10px 20px;
}

.payout_success-content {
    border-radius: var(--radius-xl, 0.75rem);
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px;
    margin-bottom: 20px;
}

.payout_cont-card-text {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
}

.payout_cont-card-ttl {
    color: #344054;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
}

.offcanvas.show {
    visibility: visible;
    transform: none;
}

.transaction_ttl {
    color: #101828;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    padding: 0 20px;
}

.successful {
    width: 120px;
    display: flex;
    padding: 0.125rem 0.5rem 0.125rem 0.375rem;
    align-items: center;
    gap: 0.125rem;
    border-radius: 100px;
    border: 1px solid #abefc6;
    background: #ecfdf3;
    color: #067647;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}

.success_varified {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.5rem;
    gap: 5px;
    justify-content: center;
    /* align-items: center; */
    color: #fff;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    background: linear-gradient(180deg, #175fc9 -20.22%, #073f82 100%);
}

.success_varified-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.benificial_accName {
    color: #fff;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
}

.benificial_acc-ttl {
    color: #fff;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
}

.create_time {
    color: #fff;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
}

.create_time span {
    color: #fff;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
}

.sec_remark-ttl {
    color: #344054;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
}

.sec_remark-desc {
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
}

.successful_img {
    width: 20px;
}

.sucees_varified_right {
    color: #fff;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}

.varified_cont-card-text {
    color: #475467;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
}

.varified_cont-card-ttl {
    color: #344054;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
}

.mps_card {
    width: 70px;
    display: flex;
    padding: 0.25rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.1875rem;
    border-radius: 0.375rem;
    border: 1px solid #175fc9;
    background: #f5f6ff;
    color: #344054;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
}

.sec_remark {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #ededed;
}

.payout_success-content {
    border-radius: var(--radius-xl, 0.75rem);
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px;
    margin-bottom: 20px;
    height: 300px;
    overflow-y: scroll;
}

.payment_detail_ttl {
    color: #101828;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    padding: 10px 0;
    margin-top: 20px;
    border-top: 1px solid #eaecf0;
}

.payment_form {
    padding: 10px 0;
}

.ifsc {
    color: #475467;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    padding: 8px 0;
}

.ifsc a {
    color: #175fc9;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    text-decoration-line: underline;
}

.MuiTableCell-head {
    font-family: "poppins" !important;
}

.MuiTableCell-body {
    font-family: "poppins" !important;
}

.css-j204z7-MuiFormControlLabel-root {
    margin-left: 0 !important;
}

.radioButtonBank {
    padding: 0px 10px 0 0 !important;
    width: unset !important;
}

.MuiButtonBase-root.MuiButton-root {
    border-radius: 0.5rem !important;
    text-transform: capitalize;
}

/* accepted: "green",
  failure: "red",
  failed: "red",
  in_process: "blue",
  pending: "orange",
  rejected: "purple",
  reversed: "yellow",
  success: "green", */

.failed {
    width: 160px;
    color: #e33737;
    padding: 0.4rem 0.5rem 0.4rem 1.375rem;
    border-radius: 100px;
    border: 1px solid #f1c2c2;
    background: #f9e5e5;
    position: relative;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}

.failed::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #e33737;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
}

.pending {
    width: 90px;
    color: #ffea00;
    padding: 0.125rem 0.5rem 0.125rem 1.375rem;
    border-radius: 100px;
    border: 1px solid #ffea00;
    background: #ffffcc;
    position: relative;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}

.pending::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #ffea00;
    position: absolute;
    left: 6px;
    top: 7px;
}

.rejected {
    width: 90px;
    color: #30a2d7;
    padding: 0.125rem 0.5rem 0.125rem 1.375rem;
    border-radius: 100px;
    border: 1px solid #30a2d7;
    background: #c8e5f3;
    position: relative;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}

.rejected::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #30a2d7;
    position: absolute;
    left: 6px;
    top: 7px;
}

.reverse {
    width: 90px;
    color: #3a11ac;
    padding: 0.125rem 0.5rem 0.125rem 1.375rem;
    border-radius: 100px;
    border: 1px solid #3a11ac;
    background: #d2c5f3;
    position: relative;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}

.reverse::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #3a11ac;
    position: absolute;
    left: 6px;
    top: 7px;
}

.paymt_crd_check {
    display: none;
    position: absolute;
    right: 12px;
    top: 12px;
}

.paymt_crd_check2 {
    position: absolute;
    right: 12px;
    top: 12px;
}

.active .paymt_crd_check {
    display: block;
}

.active .paymt_crd_check2 {
    display: none;
}

.css-ypiqx9-MuiDialogContent-root::-webkit-scrollbar {
    width: 10px;
    /* height: 2em */
    background-color: #ccc;
}

.css-ypiqx9-MuiDialogContent-root::-webkit-scrollbar-thumb {
    background: #a3a3a3;
}

/* img.payoutHead_icCreated.on.on {
    transform: rotate(180deg);
} */
.css-14htoko-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    border: 1px solid #eaecf0;
    background: #f2f4f7 !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    text-transform: capitalize !important;
    pointer-events: unset;
    cursor: pointer !important;
}

.css-u63yok-MuiButtonBase-root-MuiButton-root {
    text-transform: capitalize !important;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px !important;
    outline: 0;
 
}
.preview_popup .modal-content {
    height: 100%;
}
.preview_popup .modal-dialog{
    height: 90% !important;
}
.preview_popup .scroll-v{
    overflow-y: scroll;
    min-height: 80%;
    margin: 20px 5px;
    margin-top: 55px;
}
.preview_popup button.btn-close{
    right: 25px;
    top: 25px;
}
.preview_popup .preview-body h6{
    color: #101828;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}
.preview_popup .preview-body tr th{
    font-weight: 600;
    font-size: 0.875rem;
    background: #DEDBED;
}
.preview_popup .preview-body tr td{
    font-size: 0.75rem;
    font-weight: 500;
    color: #4B4B4B;
    border: 0;
    padding: 13px 8px;
}
.preview_popup .modal-header{
    padding: 0 1rem;
}
.beny_right-btn1 {
    display: flex;
    width: 100%;
    padding: 0.625rem var(--spacing-xl, 1rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm, 0.375rem);
    border-radius: var(--radius-md, 0.5rem);
    background: #aa0dd1;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    border: 1px solid #eaecf0;
    cursor: pointer;
    /* Adjust cursor for enabled state */
}

.beny_right-btn1:disabled,
.beny_right-btn1[disabled] {
    background: #f2f4f7;
    /* Adjust background for disabled state */
    border: 1px solid #eaecf0;
    /* Adjust border for disabled state */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    /* Adjust box-shadow for disabled state */
    cursor: not-allowed;
    /* Adjust cursor for disabled state */
    color: #98a2b3;
    /* Adjust text color for disabled state */
}

/* The container */
.checkbox_container {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #D0D5DD
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked~.checkmark {
    background-color: #175FC9;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
    left: 6.5px;
    top: 3.5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    transform: rotate(54deg);
}

.upiSuccessImg {
    margin-top: -20px;
}

.css-1nh5a5 {
    border-radius: 12px !important;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    background: #fff url(../../src/assets/images/bgpattern.png) no-repeat;
    background-position: 2px 13px;
} */

.modal-content {
    background: #fff url(../../src/assets/images/bgpattern.png) no-repeat;
}

button.beny_left-btn img {
    filter: brightness(0) saturate(100%) invert(24%) sepia(19%) saturate(757%) hue-rotate(178deg) brightness(93%) contrast(94%);
}

/* .rdrCalendarWrapper {
    color: #000000;
    font-size: 12px;
    position: absolute;
    z-index: 1111;
} */
.expDateRng-box {
    position: absolute;
    left: 20%;
    padding: 10px 20px;
    border-radius: 10px;
    z-index: 111111;
    background: #fff;
}
.opencelender {
    top: 30% !important;
}
.no-data{
    font-size: 0.789rem;
}