/* In your CSS file or <style> block */

table {
    border-collapse: collapse; /* Ensures borders between cells are combined */
    width: 100%; /* Adjusts the table width to fit its container */
    border-radius: 10px;
  }
  .milestone_tb td{
    font-weight: unset ;
    font-size: 0.75rem;
  }
  td, th {
    border: 1px solid #ddd; /* Adds a light gray border to cells */
    padding: 8px; /* Adds padding inside cells */
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2; /* Adds a background color to every other row */
  }
  
  tr:hover {
    background-color: #ddd; /* Highlights row on hover */
  }
  


  .search-input {
    /* width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px; */
  }
  .search-container.input_field{
    overflow: unset;
  }
  
  .suggestions-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 1000;
  }
  
  .suggestion-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestion-item.active {
    background-color: #007bff;
    color: #fff;
  }
  