.pagination {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  padding: 0;
  margin: 20px 30px;
}

.page-item {
  display: inline-block;
  margin-right: 5px;
}

.page-link {
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
  border: 1px solid #ccc;
  background-color: #f8f9fa;
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  box-shadow: 1px 1px 1px 1px #6c757d69;
}

.page-item.previous .page-link,
.page-item.next .page-link {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}

.page-item.previous:hover .page-link,
.page-item.next:hover .page-link {
  background-color: #5a6268;
  border-color: #545b62;
}

.page-item.disabled .page-link {
  pointer-events: none;
  color: #6c757d;
  background-color: #f8f9fa;
  border-color: #dee2e6;
}


.submit-btn1 {
  width: 100%;
  color: #f9fafc;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #aa0dd1;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm, 0.375rem);
  align-self: stretch;
  margin-top: 20px;
  cursor: pointer;
}

.submit-btn1:disabled,
.submit-btn1[disabled] {
  background: #f2f4f7;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: not-allowed;
  color: #98a2b3;
}

.activeParent .sidelink_icon {
  transition: all 0.1s;
  filter: brightness(0) saturate(100%) invert(26%) sepia(83%) saturate(1717%) hue-rotate(201deg) brightness(96%) contrast(92%);
}