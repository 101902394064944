.upi-mandate_table-box {
    overflow: scroll !important;
}

.upi-mandate_table {
    width: 100%;
    overflow: scroll !important;
}

/* .upi-mandate_table .payoutHead {
    white-space: nowrap;
} */

/* .upi-mandate_table tr th {
    width: 100%;
} */

.payer_detail {
    display: flex;
    gap: 5px;
}

.payer_detail-ttl {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.2;
    color: #475467;
}

.payer_detail-mail {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.2;
    color: #475467;
}

ul.react-tabs__tab-list {
    display: flex;
    gap: 30px;
    list-style: none;
    padding: 20px;
    /* background: #ccc; */
}

.react-tabs__tab.react-tabs__tab--selected {
    color: #175fc9;
    border-bottom: 2px solid #175fc9;

}

.react-tabs__tab {
    font-size: 1rem;
    color: #475467;
    font-weight: 600;
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
    position: relative;
}

.react-tabs__tab:focus-visible {
    outline: none;
}

.tab_box {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

}

.tab_box-btn {
    display: flex;
    align-items: center;
}


.paymentHits {
    border: 1px solid #ccc;
    padding: 2px 10px;
    border-radius: 20px;
    color: #475467;
    font-size: 0.75rem;
    background: #f9fafb;
}

.paymentHits.green::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgb(65, 193, 65);
    position: absolute;
    left: -12px;
    top: 9px;
}

.react-tabs {
    width: 100%;
}

.tab_box-btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 30px;
    /* cursor: pointer; */
}

.react-tabs__tab-panel.react-tabs__tab-panel--selected {
    width: 100%;
    overflow: auto;
}

.payment_type {
    padding: 3px 10px;
    border-radius: 10px;
    white-space: nowrap;
    border: 2px solid #ccc;
}

.edit_Btn {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
}

.paid_btn {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 9px 12px;
    color: #344054;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    border-radius: var(--radius-md, 0.5rem);
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
    margin-left: 10px;
}

.action_payment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.phone_number {
    color: #475467;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500 !important;
}

.successfully::before {
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: #17b26a;
    position: absolute;
    left: 6px;
    top: 7px;
}

.successfully {
    width: 100px;
    color: #067647;
    padding: 0.125rem 0.5rem 0.125rem 1.375rem;
    border-radius: 100px;
    border: 1px solid #abefc6;
    background: #ecfdf3;
    position: relative;
    color: #067647;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
}

table.payout_table.upi-mandate_table tr td {
    padding: 10px 5px;
    padding: 0.875rem 1.5rem
}

table.payout_table.upi-mandate_table tr:hover {
    /* border-top: 2px solid #175ec992;
    border-bottom: 1px solid #175fc9; */
    border: none;
    background: #f5f6ff;
}

.checkbox2 {
    position: absolute;
    top: 20%;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #D0D5DD;
}

.upi_exprt_btn {
    position: relative;
}

.upi_exprt_box {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 280px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}

.dragList-box {
    height: 250px;
    overflow: auto;
}

.upi_exprt_box-heading {
    font-size: 1rem;
    font-weight: 500;
    color: #475467;
    line-height: 1.2;
    margin-bottom: 20px;
}

.colSelected {
    color: #175fc9;
}

.colNotSelected {
    color: #475467b7;
}

.dragHandle {
    height: 20px;
    margin-right: 5px;
}

.dragList {
    list-style: none;
    padding: 5px 0;
}

.modal-dialog {
    height: auto !important;
    border-radius: 12px;
}

.h-30 {
    height: 30px !important;
}

.shareBy {
    display: flex;
    align-items: center;
    gap: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #175fc9;
}

input:focus+.slider {
    box-shadow: 0 0 1px #175fc9;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switchBox {
    background: #cccccc45;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
}

.switch_card {
    display: flex;
    gap: 10px;
}

.switchttl {
    font-size: 1rem;
    font-weight: 600;
}

.switchdesc {
    font-size: 1rem;
    font-weight: 400;
}

.switch-top {
    border-bottom: 2px solid #ccc;
    padding-bottom: 15px;
}

.switch-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
}

select.phoneSelect {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    outline: none;
    padding: 0.5rem;
}

input.phoneInput {
    border: none;
    outline: none;
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 0.625rem 0.875rem;
    overflow: hidden;
    color: #667085;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    width: 100%;
}

.shareBy-ttl {
    padding: 10px 0;
    font-size: 1rem;
    font-weight: 600;
    color: #475467;
}

.deailView {
    padding: 20px;
    position: relative;
}

.paymentDetail_arrow {
    position: fixed;
    top: calc(100vh - 62px);
    right: 422px;
}

.paymentDetail_arrow img {
    transform: rotate(0deg);
}

.deailView .switch {
    width: 78px;
}

.create_Imfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.create_Imfo-email {
    text-decoration: underline;
    font-size: 0.75rem;
    font-weight: 400;
    position: relative;
}

.create_Imfo-cont {
    text-decoration: underline;
    font-size: 0.75rem;
    font-weight: 400
}

.create_Imfo-email::after {
    content: "";
    width: 1px;
    height: 15px;
    background: #fff;
    position: absolute;
    top: 0;
    right: -4px;
}

.payout_success-ttl-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blue {
    color: #175fc9 !important;
}

.timeline-box {
    padding: 10px 0;
    border-bottom: 1px solid #eaecf0;
}

.acc_no-ifsc,
.acc_no-textm {
    font-weight: 300;
    font-size: 0.75rem;
    position: relative;
    color: #fff;
}

.acc_num {
    font-weight: 400;
    font-size: 0.75rem;
}

.acc_no-textm::after {
    content: "";
    width: 1px;
    height: 15px;
    background: #fff;
    position: absolute;
    top: 0;
    right: -8px;
}

.acc_no-ifsc {
    padding-left: 5px;
    color: #fff;
}

.presentation_box {
    margin: 0 -40px;
}

.presentation_head {
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #175fc9 -20.22%, #073f82 100%);
}

.presentation_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    color: #fff;
    padding: 1.5rem;
}

.presentaion_detail-btn {
    padding: 5px 10px;
    background: #fff;
    font-size: 1rem;
    font-weight: 600;
    color: rgb(25 60 106 / 86%);
    border-radius: 8px;
    width: 240px;
}