.summaryHeading{
    background: #175FC9;
    padding: 10px 29px;
}
.detail-box{
    padding: 10px 29px;
}
p{
    font-size: 12px;
}
.summaryHeading p{
    color: white;
}
.summaryHeading h6{
    color: white;
}
h5{
    font-size: 14px;
}
.heading h5{
    font-weight: 500;
}
.remarkSummary h5{
    font-weight: 400;
    color: #475467;
}
.heading_box{
    line-height: 1;
}
.innerContentBox{
    padding: 3px 0px;
}
.content_box{
   border: 1px solid grey;
   border-radius: 8px;
   padding: 5px;
}
.value p{
    font-weight: 600;
}
.remarkSummary h5{
    font-size: 13px;
}