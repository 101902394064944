.drag-drop-container {
	animation: shake 1s;
	animation-iteration-count: infinite;
}

.file-picker {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: center;
	background-color: #f8f9fa;
}

.image-preview {
	background-size: cover !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	border-radius: 1rem;
	position: absolute;
	top: 0px;
	cursor: pointer;
	transition: all 0.3s;
}

.image-preview:hover {
	opacity: 0.1;
}

/* Shake animation */
@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(0deg);
	}

	10% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}

	20% {
		transform: translate(-3px, 0px) rotate(1deg);
	}

	30% {
		transform: translate(3px, 2px) rotate(0deg);
	}

	40% {
		transform: translate(1px, -1px) rotate(1deg);
	}

	50% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}

	60% {
		transform: translate(-3px, 1px) rotate(0deg);
	}

	70% {
		transform: translate(3px, 1px) rotate(-1deg);
	}

	80% {
		transform: translate(-1px, -1px) rotate(1deg);
	}

	90% {
		transform: translate(1px, 2px) rotate(0deg);
	}

	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}
